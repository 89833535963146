import {
  schema as BaseAuditableSchema,
  SchemaInitialValues as BaseAuditableSchemaInitialValues,
} from '../Commons/BaseAuditable/validations'
import {
  schema as BaseUserSchema,
  SchemaInitialValues as BaseUserSchemaInitialValues,
} from '../Commons/BaseUser/validations'

export const schema = BaseAuditableSchema.merge(BaseUserSchema)

export const SchemaInitialValues = () => ({
  ...BaseAuditableSchemaInitialValues(),
  ...BaseUserSchemaInitialValues(),
})
