import { IdTypeValues } from '../../Types/IdType'
import { z } from 'zod'
import parsePhoneNumber from 'libphonenumber-js'

const phoneParser = (v: string) =>
  parsePhoneNumber(v, {
    extract: false,
    defaultCountry: 'CO',
  })

export const schema = z.object({
  names: z
    .string({ required_error: 'Se requiere un nombre' })
    .nonempty({ message: 'Se requiere un nombre' }),
  lastnames: z
    .string({ required_error: 'Se requiere un apellido' })
    .trim()
    .nonempty({ message: 'Se requiere un apellido' }),
  cellphone: z
    .string({ required_error: 'Se requiere un celular' })
    .trim()
    .nonempty({ message: 'Se requiere un celular' })
    .refine(
      (v) => {
        let valid = false
        try {
          const phoneNumber = phoneParser(v)
          valid = phoneNumber?.isValid() === true
        } catch (_) {}
        return valid
      },
      { message: 'Celular invalido' }
    )
    .transform((v) => {
      return phoneParser(v)?.format('E.164')
    }),
  email: z
    .string({ required_error: 'Se requiere un email' })
    .trim()
    .email({ message: 'Email invalido' }),
  address: z.string().trim().optional(),
  addressComplement: z.string().optional(),
  city: z.string().trim().optional(),
  country: z.string().trim().optional(),
  birthdate: z.date().optional(),
  idNumber: z
    .string({ required_error: 'Se requiere el número de identificación' })
    .trim()
    .nonempty({ message: 'Se requiere el número de identificación' }),
  idType: z.enum(IdTypeValues, {
    errorMap: () => {
      return { message: 'Se requiere un tipo de identificación' }
    },
  }),
  mailNotifications: z.boolean({
    required_error: 'Se requiere indicar si el usuario admite notificaciones por email',
  }),
  smsNotifications: z.boolean({
    required_error: 'Se requiere indicar si el usuario admite notificaciones por sms',
  }),
  multifactorNotifications: z.boolean({
    required_error:
      'Se requiere indicar si el usuario admite factor de doble autenticación',
  }),
  state: z.boolean({ required_error: 'Se requiere un estado' }),
  passwordReset: z.boolean({
    required_error: 'Se requiere indicar el reinicio de contraseña',
  }),
})

export const SchemaInitialValues = () => ({
  // User information
  names: '',
  lastnames: '',
  cellphone: '',
  email: '',
  address: '',
  addressComplement: '',
  city: '',
  country: '',

  birthdate: new Date(), // this doesn't work, IDKW, needs to be set after creation to work with zod
  idNumber: '',
  idType: 'CC',

  mailNotifications: true,
  smsNotifications: true,
  multifactorNotifications: true,

  state: true,
  passwordReset: true,
})
