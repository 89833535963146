import {
  SignInWithEmailAndPassword,
  SendPasswordResetEmail,
  SendEmailVerification,
  CreateUserWithEmailAndPassword,
  DeleUser,
  type TUserCredential,
  SignOut,
  GetCurrentUser,

  //MFA
  SignInMFAWithPhoneSendSMS,
  SignInMFAWithPhoneValidateCode,
  type MFResolver,
  RegisterPhoneAuth,
  ValidateCodeRegisterPhoneAuth,

  // actions
  ActionsVerifyPasswordResetCode,
  ActionsConfirmPasswordReset,
  ActionsCheckActionCode,
  ActionsApplyActionCode,
} from '../../Architecture/Application/Abstractions/Firebase/auth'

export const useGetCurrentUser = () => GetCurrentUser()

export const useSignOut = async () => SignOut()

export const useSignInWithEmailAndPassword = async (email: string, password: string) => {
  return SignInWithEmailAndPassword(email, password)
}

export const useSignInMFAWithPhoneSendSMS = async (
  errorWhenAuthWithEmailPassword: any,
  elementId: string
) => {
  return SignInMFAWithPhoneSendSMS(errorWhenAuthWithEmailPassword, elementId)
}

export const useSignInMFAWithPhoneValidateCode = async (
  verificationId: string,
  verificationCode: string,
  resolver: MFResolver
) => {
  return SignInMFAWithPhoneValidateCode(verificationId, verificationCode, resolver)
}

export const useRegisterPhoneAuth = (phoneNumber: string, elementId: string) => {
  return RegisterPhoneAuth(phoneNumber, elementId)
}

export const useValidateCodeRegisterPhoneAuth = (
  verificationId: string,
  verificationCode: string
) => {
  return ValidateCodeRegisterPhoneAuth(verificationId, verificationCode)
}

export const useSendPasswordResetEmail = async (userEmail: string) => {
  return SendPasswordResetEmail(userEmail)
}

export const useSendEmailVerification = async () => {
  return SendEmailVerification()
}

export const useCreateUserWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  return CreateUserWithEmailAndPassword(email, password)
}

export const useDeleUser = async (user: TUserCredential) => {
  return DeleUser(user)
}

export const useActionsVerifyPasswordResetCode = async (actionCode: string) => {
  return ActionsVerifyPasswordResetCode(actionCode)
}

export const useActionsConfirmPasswordReset = async (
  actionCode: string,
  newPassword: string
) => {
  return ActionsConfirmPasswordReset(actionCode, newPassword)
}

export const useActionsCheckActionCode = async (actionCode: string) => {
  return ActionsCheckActionCode(actionCode)
}

export const useActionsApplyActionCode = async (actionCode: string) => {
  return ActionsApplyActionCode(actionCode)
}
