export const DepartmentValues = [
  'Atención al cliente',
  'Administraciones',
  'Cartera',
  'Comercial',
  'Contabilidad',
  'Documentacion',
  'Mantenimiento',
  'Ocupaciones',
  'Servicios Publicos',
] as const
export type DepartmentType = (typeof DepartmentValues)[number]
