import { FirebaseTimeStampToDate } from '../Abstractions/Firebase/firestore'

const monthsAbbreviated = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const monthsFull = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]

export function FormatDateWithTimezone(date?: Date, monthAbbr = true) {
  if (date) {
    const year = date.getFullYear()
    const monthAbbreviated = (monthAbbr ? monthsAbbreviated : monthsFull)[date.getMonth()]
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')
    const timezone = date.toTimeString().split(' ')[1]

    return `${year}-${monthAbbreviated}-${day} ${hours}:${minutes}:${seconds} ${timezone}`
  }
  return ''
}

//for available timezones see: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
export function FormatDateTimeShort(date?: Date, timeZone: string = 'America/Bogota') {
  if (date) {
    // If a timeZone is provided, adjust the date and time accordingly
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone,
    }
    const formatter = new Intl.DateTimeFormat('en-US', options)

    return formatter.format(date)
  }
  return ''
}

export function FormatDateOnlyDate(date?: Date, monthAbbr = true) {
  return FormatDateWithTimezone(date, monthAbbr).split(' ')[0]
}

export function TimestampToDate(value: any): Date {
  return FirebaseTimeStampToDate(value)
}

/**
 * @param value - a string that represents a UTC date, ex: "2023-11-02T04:36:39.206Z"
 * @returns a date object
 */
export function UTCStrToDate(value: string): Date {
  return new Date(value)
}

/**
 * Convert from excel date in number format to Date object
 * @param value - a number that represents a date in excel format, where 0 represents 1/1/1900, 1 represents 2/1/1900 and so on...
 * @returns a valid Date object from the number
 */
export function ExcelNumberToDate(value: number): Date {
  const x = new Date('1/1/1900')
  x.setDate(x.getDate() + value - 2)
  return x
}

/**
 * Convert from excel string date ("dd/mm/yyyy") to a Date object
 * @param value - a date as a string in the format "dd/mm/yyyy"
 * @returns a Date object from the string
 */
export function ExcelStringToDate(value: string): Date {
  const [day, month, year] = value.split('/').map(Number)
  return new Date(year, month - 1, day)
}
