import { z } from 'zod'

export const schema = z.object({
  id: z.string(),
  idReadable: z.string().optional(),
})

export const SchemaInitialValues = () => ({
  id: '',
})
