import {
  schema as BaseMSchema,
  SchemaInitialValues as BaseMSchemaInitialValues,
} from '../BaseM/validations'
import { z } from 'zod'

export const schema = BaseMSchema.merge(
  z.object({
    lastModification: z.coerce.date(),
    lastModificationUserId: z.string(),
    creationDate: z.coerce.date(),
  })
)

export const SchemaInitialValues = () => ({
  ...BaseMSchemaInitialValues(),
  lastModification: new Date(),
  lastModificationUserId: '',
  creationDate: new Date(),
})
