import { DepartmentValues } from '../Types/DepartmentType'
import { RolesValues } from '../Types/Rolestype'
import {
  schema as BaseAuditableSchema,
  SchemaInitialValues as BaseAuditableSchemaInitialValues,
} from '../Commons/BaseAuditable/validations'
import {
  schema as BaseUserSchema,
  SchemaInitialValues as BaseUserSchemaInitialValues,
} from '../Commons/BaseUser/validations'
import { z } from 'zod'

export const schema = BaseAuditableSchema.merge(BaseUserSchema).merge(
  z.object({
    roles: z.enum(RolesValues, {
      errorMap: () => ({ message: 'Se requiere un rol' }),
    }),
    department: z.enum(DepartmentValues, {
      errorMap: () => ({ message: 'Se requiere un departamento' }),
    }),
    receiveRequestAppointments: z.boolean({
      required_error: 'Se debe especificar si recibe solicitudes/citas',
    }),
  })
)

export const SchemaInitialValues = () => ({
  ...BaseAuditableSchemaInitialValues(),
  ...BaseUserSchemaInitialValues(),
  roles: RolesValues[2],
  department: DepartmentValues[7],
  receiveRequestAppointments: true,
})
