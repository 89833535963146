/**
 * For this to work, first firebase must be initialized
 */

import {
  getAuth,
  // sign-in and sign-out
  signInWithEmailAndPassword,
  signOut,
  // user management
  createUserWithEmailAndPassword,
  deleteUser,
  sendEmailVerification,
  sendPasswordResetEmail,
  // MFA
  RecaptchaVerifier,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
  // actions to be completed by the user
  verifyPasswordResetCode,
  confirmPasswordReset,
  checkActionCode,
  applyActionCode,
  // error codes
  AuthErrorCodes,
} from 'firebase/auth'

import type { UserCredential, MultiFactorResolver } from 'firebase/auth'

export type MFResolver = MultiFactorResolver
export type TUserCredential = UserCredential

export const useAuth = () => {
  return getAuth()
}

export const GetCurrentUser = () => {
  return useAuth().currentUser
}

export const SignInWithEmailAndPassword = async (email: string, password: string) => {
  return signInWithEmailAndPassword(useAuth(), email, password)
}

export const SignOut = async () => {
  return signOut(useAuth())
}

export const RecaptchaVerifierObject = (elementId: string, callback: any) => {
  return new RecaptchaVerifier(useAuth(), elementId, {
    size: 'invisible',
    callback: function () {
      // function(response)
      // reCAPTCHA solved, you can proceed with
      // phoneAuthProvider.verifyPhoneNumber(...).
      if (callback) callback()
    },
  })
}

export const RegisterPhoneAuth = (phoneNumber: string, elementId: string) => {
  const recaptchaVerifier = RecaptchaVerifierObject(elementId, null)
  return multiFactor(GetCurrentUser()!)
    .getSession()
    .then(function (multiFactorSession) {
      // Specify the phone number and pass the MFA session.
      const phoneInfoOptions = {
        phoneNumber: phoneNumber,
        session: multiFactorSession,
      }

      const phoneAuthProvider = new PhoneAuthProvider(useAuth())

      // Send SMS verification code.
      return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
    })
    .then(function (verificationId) {
      return verificationId
    })
}

export const ValidateCodeRegisterPhoneAuth = (
  verificationId: string,
  verificationCode: string
) => {
  // the verificationId is generate by useRegisterPhoneAuth
  // Ask user for the verification code. Then:
  const cred = PhoneAuthProvider.credential(verificationId, verificationCode)
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

  // Complete enrollment.
  return multiFactor(GetCurrentUser()!).enroll(multiFactorAssertion, 'phoneAuthMethod')
}

export const CreateUserWithEmailAndPassword = async (email: string, password: string) => {
  return createUserWithEmailAndPassword(useAuth(), email, password)
    .then((value) => {
      // console.log('usecreateUserWithEmailAndPassword ok')
      return { userCred: value, created: true, error: '' }
    })
    .catch((error) => {
      // console.log('usecreateUserWithEmailAndPassword error')
      const errorCode = error.code
      const result = { userCred: null, created: false, error: '' }
      if (errorCode == AuthErrorCodes.WEAK_PASSWORD) {
        result.error = 'Contraseña muy debil'
      } else if (errorCode == AuthErrorCodes.INVALID_EMAIL) {
        result.error = 'Correo invalido'
      } else if (errorCode == AuthErrorCodes.EMAIL_EXISTS) {
        result.error = 'Correo en uso'
      } else {
        result.error = 'Error creando el usuario'
        console.log('Error creando el usuario: ', error)
      }
      return result
    })
}
export type TCreateWEmailPassword = Awaited<
  ReturnType<typeof CreateUserWithEmailAndPassword>
>

export const DeleUser = async (user: UserCredential) => {
  return deleteUser(user.user)
    .then(() => ({ deleted: true, error: null }))
    .catch((error) => ({ deleted: false, error }))
}

/**
 * This function send an email to the user email to reset the password
 * @param userEmail
 * @returns
 */
export const SendPasswordResetEmail = async (userEmail: string) => {
  return sendPasswordResetEmail(useAuth(), userEmail)
    .then(() => ({ sended: true, error: null }))
    .catch((error) => ({ sended: false, error }))
}

/**
 * This function send an email to the user email to verify the email (needed to use 2FA)
 * @returns
 */
export const SendEmailVerification = async () => {
  return await sendEmailVerification(GetCurrentUser()!)
    .catch((err) => console.log('error send firebase', err))
    .then(() => console.log('code sent firebase'))
}

/**
 * This function send an sms to the user number to sign in
 * @param errorWhenAuthWithEmailPassword
 * @param elementId
 * @returns
 */
export const SignInMFAWithPhoneSendSMS = async (
  errorWhenAuthWithEmailPassword: any,
  elementId: string
) => {
  const recaptchaVerifier = RecaptchaVerifierObject(elementId, null)
  const resolver = getMultiFactorResolver(useAuth(), errorWhenAuthWithEmailPassword)
  const phoneInfoOptions = {
    multiFactorHint: resolver.hints[0],
    session: resolver.session,
  }
  const phoneAuthProvider = new PhoneAuthProvider(useAuth())
  // Send SMS verification code
  return phoneAuthProvider
    .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
    .then(function (verificationId) {
      return { verificationId, resolver }
    })
}

/**
 * This function validates the code that the user received via sms and completes the sign in process
 * @param verificationId
 * @param verificationCode
 * @param resolver
 * @returns
 */
export const SignInMFAWithPhoneValidateCode = async (
  verificationId: string,
  verificationCode: string,
  resolver: MultiFactorResolver
) => {
  const cred = PhoneAuthProvider.credential(verificationId, verificationCode)
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
  // Complete sign-in.
  return resolver.resolveSignIn(multiFactorAssertion)
}

/**
 * This function is use on the resetPassword mode action, and verify the code that the user receives
 * @param actionCode
 * @returns
 */
export const ActionsVerifyPasswordResetCode = async (actionCode: string) => {
  return verifyPasswordResetCode(getAuth(), actionCode)
}

/**
 * This function is use on the resetPassword mode action, and sets the new password given to the user associated with the actionCode
 * @param actionCode
 * @param newPassword
 * @returns
 */
export const ActionsConfirmPasswordReset = async (
  actionCode: string,
  newPassword: string
) => {
  return confirmPasswordReset(getAuth(), actionCode, newPassword)
}

/**
 * This function is use on multiple actions that the user needs to validate and is use to validate the action code sended to the user
 * @param actionCode
 * @returns
 */
export const ActionsCheckActionCode = async (actionCode: string) => {
  return checkActionCode(getAuth(), actionCode)
}

/**
 * This function is use on multiple actions that the user needs to validate and is use to apply the action code sended to the user
 * @param actionCode
 * @returns
 */
export const ActionsApplyActionCode = async (actionCode: string) => {
  return applyActionCode(getAuth(), actionCode)
}
