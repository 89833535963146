import { acceptHMRUpdate, defineStore } from 'pinia'
import { type RemovableRef, StorageSerializers, useStorage } from '@vueuse/core'
import { cloneDeep } from 'lodash'

import { type RolesType } from '/@Architecture/Application/Types/Rolestype'
import { type DepartmentType } from '/@Architecture/Application/Types/DepartmentType'
import { type User } from '/@Architecture/Application/User/models'
import { SchemaInitialValues as UserSchemaInitialValues } from '/@Architecture/Application/User/validations'
import { type Adviser } from '/@Architecture/Application/Adviser/models'

import { UTCStrToDate } from '/@src/utils/datetime'
import { useSignOut } from '/@src/composable/useAuth'

type AppUser = User
const defaultUser = () => UserSchemaInitialValues(false) as User

export const useUserSession = defineStore('userSession', () => {
  // token will be synced with local storage
  // @see https://vueuse.org/core/usestorage/
  const appUser: RemovableRef<AppUser> = useStorage('user', defaultUser, undefined, {
    serializer: StorageSerializers.object,
    deep: true,
    mergeDefaults: true,
  })

  const defaultMfaAuthValue = () => ({ required: true, valid: false })
  type TmfaAuth = ReturnType<typeof defaultMfaAuthValue>
  /**
   * - `mfaAuth.value.required` indicates if mfa is required for the user
   * - `mfaAuth.value.valid` is true when mfa is required and the user correctly authenticates with mfa
   */
  const mfaAuth = useStorage('mfa', defaultMfaAuthValue())

  const loading = ref(true)

  const isLoggedIn = computed(() => {
    return appUser.value.id !== '' && (!mfaAuth.value.required || mfaAuth.value.valid)
  })
  const userRole: globalThis.ComputedRef<RolesType | undefined> = computed(
    () => (appUser as RemovableRef<Adviser>).value?.roles
  )
  const userDepartment: globalThis.ComputedRef<DepartmentType | undefined> = computed(
    () => (appUser as RemovableRef<Adviser>).value?.department
  )

  function setAppUser(user: AppUser): void {
    appUser.value = user
  }

  /**
   * @returns a valid object of User type, because appUser is converted to a serializable object and for example objects of type Date are converted to string
   */
  function getUser(): User {
    const u = cloneDeep(appUser.value as User)
    u.birthdate = UTCStrToDate(u.birthdate as unknown as string)
    u.creationDate = UTCStrToDate(u.creationDate as unknown as string)
    u.lastModification = UTCStrToDate(u.lastModification as unknown as string)
    return u
  }

  function isAdviser(): boolean {
    return appUser.value?.hasOwnProperty('roles') ?? false
  }

  function hasRole(role: RolesType): boolean {
    if (isAdviser()) {
      // TODO: FIX THIS TYPE, INCORRECT USE OF AS
      return (appUser as unknown as globalThis.Ref<Adviser>).value.roles === role
    }
    return false
  }

  function hasDepartment(department: DepartmentType): boolean {
    if (isAdviser()) {
      // TODO: WWWFIX THIS TYPE, INCORRECT USE OF AS
      return (
        (appUser as unknown as globalThis.Ref<Adviser>).value.department === department
      )
    }
    return false
  }

  function setMfaAuth(mfa: TmfaAuth) {
    mfaAuth.value = mfa
  }

  function setLoading(newLoading: boolean) {
    loading.value = newLoading
  }

  function logoutUser() {
    setAppUser(defaultUser())
    setMfaAuth(defaultMfaAuthValue())
    useSignOut()
  }

  return {
    appUser,
    mfaAuth,
    isLoggedIn,
    userRole,
    userDepartment,
    loading,
    logoutUser,
    getUser,
    setAppUser,
    isAdviser,
    hasRole,
    hasDepartment,
    setMfaAuth,
    setLoading,
  } as const
})

/**
 * Pinia supports Hot Module replacement so you can edit your stores and
 * interact with them directly in your app without reloading the page.
 *
 * @see https://pinia.esm.dev/cookbook/hot-module-replacement.html
 * @see https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserSession, import.meta.hot))
}
