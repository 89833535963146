import {
  schema as AdviserSchema,
  SchemaInitialValues as AdviserSchemaInitialValues,
} from '../Adviser/validations'
import {
  schema as ClientSchema,
  SchemaInitialValues as ClientSchemaInitialValues,
} from '../Client/validations'

export const schema = (isAdviser: boolean) => (isAdviser ? AdviserSchema : ClientSchema)

export const SchemaInitialValues = (isAdviser: boolean) =>
  isAdviser ? AdviserSchemaInitialValues() : ClientSchemaInitialValues()
