import { _HasDataLoaderMeta } from 'unplugin-vue-router/runtime'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/[...all].vue'),
    /* no children */
  },
  {
    path: '/app',
    name: '/app',
    component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app.vue'),
    children: [
      {
        path: '',
        name: '/app/',
        component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/index.vue'),
        /* no children */
      },
      {
        path: 'administracionInmuebles',
        /* internal name: '/app/administracionInmuebles' */
        /* no component */
        children: [
          {
            path: '',
            name: '/app/administracionInmuebles/',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/administracionInmuebles/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'agendamientos',
        /* internal name: '/app/agendamientos' */
        /* no component */
        children: [
          {
            path: '',
            name: '/app/agendamientos/',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/agendamientos/index.vue'),
            /* no children */
          },
          {
            path: ':agendamiento',
            name: '/app/agendamientos/[agendamiento]',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/agendamientos/[agendamiento].vue'),
            /* no children */
          },
          {
            path: 'agregar',
            name: '/app/agendamientos/agregar',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/agendamientos/agregar.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'asesores',
        /* internal name: '/app/asesores' */
        /* no component */
        children: [
          {
            path: '',
            name: '/app/asesores/',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/asesores/index.vue'),
            /* no children */
            meta: {
              "requiresRoles": [
                "Director",
                "Admin"
              ]
            }
          },
          {
            path: ':asesorId',
            name: '/app/asesores/[asesorId]',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/asesores/[asesorId].vue'),
            /* no children */
            meta: {
              "requiresRoles": [
                "Director",
                "Admin"
              ]
            }
          },
          {
            path: 'agregar',
            name: '/app/asesores/agregar',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/asesores/agregar.vue'),
            /* no children */
            meta: {
              "requiresRoles": [
                "Director",
                "Admin"
              ]
            }
          }
        ],
      },
      {
        path: 'citas',
        /* internal name: '/app/citas' */
        /* no component */
        children: [
          {
            path: '',
            name: '/app/citas/',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/citas/index.vue'),
            /* no children */
          },
          {
            path: ':citaId',
            name: '/app/citas/[citaId]',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/citas/[citaId].vue'),
            /* no children */
          },
          {
            path: 'agregar',
            name: '/app/citas/agregar',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/citas/agregar.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'clientes',
        /* internal name: '/app/clientes' */
        /* no component */
        children: [
          {
            path: '',
            name: '/app/clientes/',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/clientes/index.vue'),
            /* no children */
            meta: {
              "requiresAdviser": true
            }
          },
          {
            path: ':clienteId',
            name: '/app/clientes/[clienteId]',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/clientes/[clienteId].vue'),
            /* no children */
            meta: {
              "requiresAdviser": true
            }
          },
          {
            path: 'agregar',
            name: '/app/clientes/agregar',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/clientes/agregar.vue'),
            /* no children */
            meta: {
              "requiresAdviser": true
            }
          },
          {
            path: 'cargamasiva',
            name: '/app/clientes/cargamasiva',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/clientes/cargamasiva.vue'),
            /* no children */
            meta: {
              "requiresAdviser": true
            }
          }
        ],
      },
      {
        path: 'documentacion',
        /* internal name: '/app/documentacion' */
        /* no component */
        children: [
          {
            path: '',
            name: '/app/documentacion/',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/documentacion/index.vue'),
            /* no children */
          },
          {
            path: 'agregar',
            name: '/app/documentacion/agregar',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/documentacion/agregar.vue'),
            /* no children */
          },
          {
            path: 'cargamasiva',
            name: '/app/documentacion/cargamasiva',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/documentacion/cargamasiva.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'indicadores',
        /* internal name: '/app/indicadores' */
        /* no component */
        children: [
          {
            path: '',
            name: '/app/indicadores/',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/indicadores/index.vue'),
            /* no children */
            meta: {
              "requiresAdviser": true
            }
          },
          {
            path: 'asesores',
            name: '/app/indicadores/asesores',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/indicadores/asesores.vue'),
            /* no children */
            meta: {
              "requiresAdviser": true
            }
          }
        ],
      },
      {
        path: 'inmuebles',
        /* internal name: '/app/inmuebles' */
        /* no component */
        children: [
          {
            path: '',
            name: '/app/inmuebles/',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/inmuebles/index.vue'),
            /* no children */
            meta: {
              "requiresAdviser": true
            }
          },
          {
            path: ':inmuebleId',
            name: '/app/inmuebles/[inmuebleId]',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/inmuebles/[inmuebleId].vue'),
            /* no children */
            meta: {
              "requiresAdviser": true
            }
          },
          {
            path: 'agregar',
            name: '/app/inmuebles/agregar',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/inmuebles/agregar.vue'),
            /* no children */
            meta: {
              "requiresAdviser": true
            }
          },
          {
            path: 'cargamasiva',
            name: '/app/inmuebles/cargamasiva',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/inmuebles/cargamasiva.vue'),
            /* no children */
            meta: {
              "requiresAdviser": true
            }
          }
        ],
      },
      {
        path: 'perfil',
        /* internal name: '/app/perfil' */
        /* no component */
        children: [
          {
            path: '',
            name: '/app/perfil/',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/perfil/index.vue'),
            /* no children */
          },
          {
            path: 'editar',
            name: '/app/perfil/editar',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/perfil/editar.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'preguntasfrecuentes',
        /* internal name: '/app/preguntasfrecuentes' */
        /* no component */
        children: [
          {
            path: '',
            name: '/app/preguntasfrecuentes/',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/preguntasfrecuentes/index.vue'),
            /* no children */
          },
          {
            path: 'pregunta',
            name: '/app/preguntasfrecuentes/pregunta',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/preguntasfrecuentes/pregunta.vue'),
            /* no children */
          },
          {
            path: 'seccion',
            name: '/app/preguntasfrecuentes/seccion',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/preguntasfrecuentes/seccion.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'referencia',
        /* internal name: '/app/referencia' */
        /* no component */
        children: [
          {
            path: '',
            name: '/app/referencia/',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/referencia/index.vue'),
            /* no children */
          },
          {
            path: 'agregar',
            name: '/app/referencia/agregar',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/referencia/agregar.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'solicitudes',
        /* internal name: '/app/solicitudes' */
        /* no component */
        children: [
          {
            path: '',
            name: '/app/solicitudes/',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/solicitudes/index.vue'),
            /* no children */
          },
          {
            path: ':solicitudId',
            name: '/app/solicitudes/[solicitudId]',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/solicitudes/[solicitudId].vue'),
            /* no children */
          },
          {
            path: 'agregar',
            name: '/app/solicitudes/agregar',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/solicitudes/agregar.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 't',
        /* internal name: '/app/t' */
        /* no component */
        children: [
          {
            path: '',
            name: '/app/t/',
            component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/app/t/index.vue'),
            /* no children */
          }
        ],
      }
    ],
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/auth',
    name: '/auth',
    component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/auth.vue'),
    children: [
      {
        path: 'action',
        name: '/auth/action',
        component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/auth/action.vue'),
        /* no children */
      },
      {
        path: 'emailverification',
        name: '/auth/emailverification',
        component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/auth/emailverification.vue'),
        /* no children */
      },
      {
        path: 'login',
        name: '/auth/login',
        component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/auth/login.vue'),
        /* no children */
      },
      {
        path: 'logincreatemfa',
        name: '/auth/logincreatemfa',
        component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/auth/logincreatemfa.vue'),
        /* no children */
      },
      {
        path: 'resetpassword',
        name: '/auth/resetpassword',
        component: () => import('/home/runner/work/quickstarter-vuero-v2.7.0/quickstarter-vuero-v2.7.0/src/pages/auth/resetpassword.vue'),
        /* no children */
      }
    ],
  }
]
