import { definePlugin } from '/@src/app'

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth, onIdTokenChanged } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getStorage } from 'firebase/storage'
import { firebaseConfig } from '/@Architecture/Application/Abstractions/Firebase/config'

import { useUserSession } from '/@src/stores/userSession'

// https://firebase.google.com/docs/web/setup#available-libraries

export const firebaseSymbol: InjectionKey<
  Awaited<ReturnType<typeof initFirebasefServices>>
> = Symbol('firebase')

export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const firestore = getFirestore(app)
export const functions = getFunctions(app)
export const storage = getStorage(app)

if (import.meta.env.DEV) {
  connectFunctionsEmulator(functions, '127.0.0.1', 5001)
  console.log('running firebase emulation (make sure that is running!).')
}

async function initFirebasefServices() {
  // let firebaseServices: any | null // TODO: FIX THIS TYPE

  if (!import.meta.env.SSR) {
    // Initialize Firebase
  }

  const firebaseServices = {
    app,
    auth,
    firestore,
    functions,
    storage,
  }
  return firebaseServices
}

export default definePlugin(async ({ app, pinia, router }) => {
  const userSession = useUserSession(pinia)
  const firebase = await initFirebasefServices()
  onIdTokenChanged(
    auth,
    (user) => {
      // console.log('user changed', user)
      if (!user && userSession.isLoggedIn) {
        userSession.logoutUser()
        router.push('auth/login')
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (e) => {
      // console.log('auth error, e:', e)
    },
    () => {
      // console.log('auth observer is removed')
    }
  )
  app.provide(firebaseSymbol, firebase)
})
